<template>
    <b-button
        @click="changeVerified(row.item.id)"
        size="sm"
        :variant="row.item.verified == 1 ? 'success' : 'danger'"
    >
        <b-icon :icon="row.item.verified == 1 ? 'check-circle' : 'x-circle'"></b-icon>
    </b-button>
</template>

<script>
export default {
    props: {
        'row': [Object],
        'refreshTable': Function
    },
    methods: {
        changeVerified(id) {
            this.$store.dispatch('IncomingInvoices/changeVerifiedIncomingInvoice', id).finally(() => {
                this.refreshTable()
            })
        },
    }
}
</script>